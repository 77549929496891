import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {Wizard} from 'shared';
import {WizardNavigation} from 'shared/components/Wizard/WizardNavigation';
import {CBCButton, Loader, useQueryParams} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useProductItem} from 'hooks';
import {getTabErrors} from 'components/customer/Product/helpers/getTabErrors';
import {Formik} from 'formik';
import {Specs, InvalidProduct} from 'components/customer/Product';
import {
    useProductContext,
    useNotificationContext,
    useAppContext,
} from 'contexts';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {allDataCleared} from 'components/customer/Materials/store/materialSlice';
import {clearAllEdgeFinishData} from 'components/customer/EdgeFinishes/store/edgeFinishSlice';
import {useLazyGetProductDataQuery} from './store/productApi';
import ProductDescription from 'components/customer/Product/Description/ProductDescription';
import CBCDialog from 'shared/components/ConfirmationDialog/CBCDialog';
import Footer, {
    CloseEventType,
} from 'shared/components/ConfirmationDialog/Footer';
import RoomAssistantTable from 'shared/components/ConfirmationDialog/RoomAssistantTable';
import {cloneDeep} from 'lodash';
import {CancelButton} from 'shared/components/CancelButton';
import {
    setIsSavingProduct,
    getHasPreview3DLoading,
} from 'components/customer/Preview3DCommon/store/viewerSlice';

export const Product = () => {
    const dispatch = useAppDispatch();
    const [changeManufacturerDescription, setChangeManufacturerDescription] =
        useState(0);
    const [formValues, setFormValues] = useState({});

    const {
        currentTab,
        initialValues,
        formSchema,
        formStructure,
        addToFavourite,
        productFormHandler,
        productFieldValidator,
        tabErrors,
        setTabErrors,
        isFavourite,
        favouriteButtonDisabled,
        loading,
        setLoading,
        showRoomDefaultDialog,
        setShowRoomDefaultDialog,
        productFormSubmitHandler,
    } = useProductItem();
    const {
        setCurrentTab,
        getProductInfo,
        productDataStore,
        formSubmitCallback,
        formSubmitCheck,
        setFormSubmitCheck,
        getMaterialOptions,
        isLoading,
        areRunnersLoading,
        roomAssitantContent,
        showDialog,
        hideDialog,
    } = useProductContext();
    const {refreshUserData} = useAppContext();
    const {addMessages, MESSAGE_TYPES} = useNotificationContext();
    const {cabinetId, copy, product} = useQueryParams();
    const {roomId} = useParams();
    const materialOptions = getMaterialOptions();
    const inSizesTab = [0, 1, 2, 3, 4].includes(currentTab);
    const wizardHeaderRef = useRef(null);
    const hasPreview3DLoading = useAppSelector(getHasPreview3DLoading);

    const [getProductData, {data: productData}] = useLazyGetProductDataQuery();
    useEffect(() => {
        getProductData({
            cabinetType: parseInt(product),
            roomId: parseInt(roomId),
            cabinetId,
        });
    }, [getProductData, product, cabinetId]);

    const isSmallDevice = isDeviceSmall();
    let manufacturerDescription = getProductInfo('manufacturer_description');

    let manufacturerShortDescription = '';
    if (isSmallDevice) {
        // truncate longstring if on mobile
        manufacturerShortDescription =
            manufacturerDescription && manufacturerDescription.length > 90
                ? `${manufacturerDescription.substring(0, 90)}...[Read More]`
                : manufacturerDescription;
    }

    // chnage description for small devices based on state
    manufacturerDescription = isSmallDevice
        ? !changeManufacturerDescription
            ? manufacturerShortDescription
            : `${manufacturerDescription} [Read Less]`
        : manufacturerDescription;

    useEffect(() => {
        dispatch(setIsSavingProduct(false));
        return () => {
            dispatch(allDataCleared());
            dispatch(clearAllEdgeFinishData());
        };
    }, []);

    useEffect(() => {
        setCurrentTab(0);
    }, [product]);

    const toggleManufacturerDescription = (changeState) => (event) => {
        if (changeState) {
            setChangeManufacturerDescription(!changeManufacturerDescription);
        }
    };

    let invalidProduct = false;
    if (
        productDataStore.current.hasOwnProperty('invalid_cabinet') &&
        productDataStore.current.invalid_cabinet
    ) {
        invalidProduct = true;
    }

    const imageColumn = useMemo(
        () => (
            <ProductDescription
                manufacturerDescription={manufacturerDescription}
                toggleManufacturerDescription={toggleManufacturerDescription}
                productData={productData}
                formStructure={formStructure}
                inSizesTab={inSizesTab}
                addToFavourite={addToFavourite}
                isFavourite={isFavourite}
                favouriteButtonDisabled={favouriteButtonDisabled}
                formValues={formValues}
            />
        ),
        [
            currentTab,
            manufacturerDescription,
            isFavourite,
            materialOptions,
            productData,
            formValues,
        ]
    );

    const {wizard, maxTab} = useMemo(() => {
        let productTabEventKey;
        let maxTab = 0;
        let wizard = <></>;

        const getEventKey = () => {
            if (typeof productTabEventKey === 'undefined')
                productTabEventKey = 0;
            else productTabEventKey++;

            return productTabEventKey;
        };

        let productConfig = JSON.parse(JSON.stringify(formStructure));

        if (productConfig) {
            productConfig = productConfig.filter(
                (structure) =>
                    structure?.layout != 'sidebar' &&
                    (structure.options?.visible ?? true)
            );

            maxTab = productConfig.length - 1;

            wizard = (
                <Wizard
                    tabErrors={tabErrors}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    id="product-page-wizard"
                    inSizesTab={inSizesTab}
                    imageColumn={!isSmallDevice ? imageColumn : null}
                    wizardHeaderRef={wizardHeaderRef}>
                    {productConfig.map((config, index) => (
                        <Specs
                            title={config.name}
                            preview={config.preview}
                            fieldsets={config.fieldsets}
                            eventKey={getEventKey()}
                            key={index}
                            structure={config}
                        />
                    ))}
                </Wizard>
            );
        }

        return {
            wizard,
            maxTab,
        };
    }, [
        cabinetId,
        inSizesTab,
        currentTab,
        formStructure,
        tabErrors,
        isFavourite,
        isSmallDevice,
        wizardHeaderRef,
    ]);

    const dynamicColumns = useMemo(() => {
        if (!isSmallDevice) {
            return (
                <Row>
                    <Col className="product-form-fields">{wizard}</Col>
                </Row>
            );
        } else {
            return (
                <Row>
                    {imageColumn}
                    <Col className="product-form-fields">{wizard}</Col>
                </Row>
            );
        }
    }, [inSizesTab, imageColumn, isFavourite, isSmallDevice]);

    const toggleRoomAssistantDialogues = useCallback(
        (event) => {
            setShowRoomDefaultDialog(!showRoomDefaultDialog);
            if (event == CloseEventType.APPLY) {
                setLoading(true);
                productFormSubmitHandler(roomAssitantContent);
            }
            if (event == CloseEventType.CANCEL) {
                setLoading(false);
            }
            if (event == CloseEventType.OK) {
                setLoading(true);
                const data = cloneDeep(roomAssitantContent);
                data['changed_data'].map((data) => {
                    data.items.map((defaultData) => {
                        defaultData.apply_to_room = false;
                    });
                });
                productFormSubmitHandler(data);
            }
        },
        [showRoomDefaultDialog, roomAssitantContent]
    );

    const handleCancelClick = useCallback(() => {
        setShowRoomDefaultDialog(false);
        showDialog({
            title: 'Are you sure you want to turn off Room Assistant?',
            message:
                'Your product changes will be saved and the Room Assistant feature will be turned off.<br/><br/>These changes will not affect your room settings, or the defaults for future products that you add to this order.<br/><br/> <i>(You can reactivate the Room Assistant feature through Account Settings > Settings > Use Room Assistant)</i>',
            hideYesButton: true,
            hideNoButton: true,
            keyboard: false,
            buttons: [
                {
                    name: 'Cancel',
                    alignLeft: true,
                    variant: 'danger',
                    show: true,
                    action: async () => {
                        hideDialog();
                        setLoading(false);
                    },
                },
                {
                    name: 'Turn Off For This Job',
                    show: true,
                    action: async () => {
                        setLoading(true);
                        const data = cloneDeep(roomAssitantContent);
                        data['toggle_room_assistant_for_job'] = false;
                        await productFormSubmitHandler(data);
                        refreshUserData();
                    },
                },
                {
                    name: 'Turn Off',
                    show: true,
                    action: async () => {
                        setLoading(true);
                        const data = cloneDeep(roomAssitantContent);
                        data['toggle_room_assistant'] = false;
                        await productFormSubmitHandler(data);
                        refreshUserData();
                    },
                },
            ],
        });
        setLoading(false);
    }, [roomAssitantContent]);

    return (
        <>
            <CBCDialog
                display={showRoomDefaultDialog}
                title="Update Product Defaults?"
                footer={
                    <Footer
                        yesButtonText={`Save & Update Defaults`}
                        noButtonText={`Continue Editing`}
                        okButtonText={`Save & Don't Update Defaults`}
                        onClose={toggleRoomAssistantDialogues}
                        okButtonClassname="button-transparent"
                    />
                }>
                <RoomAssistantTable handleClick={handleCancelClick} />
            </CBCDialog>
            <Loader loader={loading} hideInitially={true} />
            {invalidProduct ? (
                <InvalidProduct
                    message={productDataStore.current.invalid_cabinet_message}
                />
            ) : initialValues &&
              formSchema &&
              Object.keys(formSchema).length &&
              Object.keys(initialValues).length ? (
                <Formik
                    enableReinitialize={true}
                    validationSchema={formSchema}
                    initialValues={initialValues}
                    onSubmit={productFormHandler}>
                    {({
                        values,
                        errors,
                        handleSubmit,
                        submitForm,
                        validateForm,
                    }) => {
                        useEffect(() => {
                            productFieldValidator(values, errors);
                            setFormValues(values);
                        }, [values, errors]);

                        useEffect(() => {
                            validateForm();
                        }, [formSchema]);

                        useEffect(() => {
                            validateForm(values).then((err) => {
                                const errorFields = Object.keys(err);
                                if (errorFields.length) {
                                    const messages = errorFields.map(
                                        (field) => ({
                                            message: err[String(field)],
                                            fields: [field],
                                            onlyField: true,
                                        })
                                    );

                                    addMessages({
                                        type: MESSAGE_TYPES.ERROR,
                                        messages,
                                    });
                                    setTabErrors(
                                        getTabErrors(formStructure, messages)
                                    );
                                } else {
                                    setTabErrors({});
                                    addMessages({});
                                }
                            });
                        }, [errors]);

                        return (
                            <Form
                                noValidate
                                onSubmit={handleSubmit}
                                className="cbc-form"
                                id="product-form"
                                style={{padding: '0'}}>
                                <Container>{dynamicColumns}</Container>

                                <div className="nav-and-control">
                                    <Container>
                                        <Row>
                                            <Col md={{offset: 1, span: 10}}>
                                                <WizardNavigation
                                                    currentTab={currentTab}
                                                    setCurrentTab={
                                                        setCurrentTab
                                                    }
                                                    max={maxTab}
                                                    dots={maxTab + 1}
                                                    wizardHeaderRef={
                                                        wizardHeaderRef
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="control-buttons">
                                            <Col
                                                xs={6}
                                                md={{offset: 2, span: 4}}>
                                                <CancelButton />
                                            </Col>
                                            <Col xs={6} md={4}>
                                                <CBCButton
                                                    disabled={
                                                        isLoading ||
                                                        hasPreview3DLoading
                                                    }
                                                    id="product-submit-button"
                                                    type="button"
                                                    onClick={() => {
                                                        dispatch(
                                                            setIsSavingProduct(
                                                                true
                                                            )
                                                        );
                                                        if (
                                                            formSubmitCheck ||
                                                            areRunnersLoading()
                                                        ) {
                                                            formSubmitCallback.current =
                                                                () => {
                                                                    submitForm();
                                                                    formSubmitCallback.current = false;
                                                                    setFormSubmitCheck(
                                                                        false
                                                                    );
                                                                };
                                                        } else {
                                                            // putting setTimeOut to defer the priority of submitForm execution
                                                            // this will ensure execution after async formik.values updates via hooks/useEffects
                                                            setTimeout(() => {
                                                                submitForm();
                                                            }, 0);
                                                        }
                                                    }}
                                                    iconName="Button-Tick.svg"
                                                    className="job-button button-blue">
                                                    {cabinetId && !copy
                                                        ? 'Update Product'
                                                        : 'Save Product'}
                                                </CBCButton>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : null}
        </>
    );
};
